import { useEffect, useState } from 'react';

export const useTimer = (seconds: number = 0, interval: number = 1000): number => {
  const [timeLeft, setTimeLeft] = useState(seconds * 1000);

  useEffect(() => {
    let id: NodeJS.Timeout;
    if (seconds && timeLeft > 0) {
      id = setTimeout(() => {
        setTimeLeft(prev => prev - interval);
      }, interval);
    }

    return () => {
      clearTimeout(id);
    };
  });

  return timeLeft / 1000;
};
