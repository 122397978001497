import { useSnackbar } from 'notistack';

export const useNotification = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  return {
    notify: enqueueSnackbar,
    dismissNotification: closeSnackbar,
  };
};
