import { Snackbar, SnackbarProvider, CloseAll } from 'features/notifications';
import type { SnackbarProviderProps } from 'features/notifications';
import { useState } from 'react';
import { BugDroid, CheckCircle, Info, Warning } from '@phosphor-icons/react';
import { createPortal } from 'react-dom';
export type NotificationProviderProps = SnackbarProviderProps;

// Why rename it here and not use SnackbarProvider? We do not know exactly what
// types of notifications we will have in the future. We could add/wrap additional
// notification functionality here
export const NotificationProvider = ({ children }: SnackbarProviderProps) => {
  const [count, setCount] = useState(0);

  const notistackContainer = document.querySelector('.notistack-SnackbarContainer');

  return (
    <SnackbarProvider
      Components={{
        // We use the same component for all variants
        // because the addition for a close button, which required custom component
        // Variant styling is done within the component
        default: Snackbar,
        warning: Snackbar,
        success: Snackbar,
        error: Snackbar,
        info: Snackbar,
      }}
      iconVariant={{
        success: <CheckCircle size={20} />,
        info: <Info size={20} />,
        warning: <Warning size={20} />,
        error: <BugDroid size={20} />,
      }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      onExited={() => {
        setCount(prev => (prev > 0 ? prev - 1 : prev));
      }}
      onEntered={() => {
        setCount(prev => prev + 1);
      }}
      maxSnack={10}
    >
      {children}
      {notistackContainer && createPortal(count > 1 ? <CloseAll /> : null, notistackContainer)}
    </SnackbarProvider>
  );
};
