import { useRef, useEffect } from 'react';

const inputElements = ['TEXTAREA', 'INPUT', 'SELECT'];
const shouldSuppressEvent = (suppressInInputs: boolean, key: string) => {
  // always suppress enter so you can make new lines in text area
  if (key === 'Enter' && document.activeElement?.tagName === 'TEXTAREA') {
    return true;
  }
  if (suppressInInputs && inputElements.indexOf(document.activeElement?.tagName ?? '') > -1) {
    return true;
  }
  return false;
};

export const useKeyPress = (
  key: string,
  handler: (event: KeyboardEvent, keyName: string) => void,
  suppressInInputs = false,
  element = window
) => {
  const savedHandler = useRef<(event: KeyboardEvent, keyName: string) => void>();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const isSupported = element && element.addEventListener;
    if (!isSupported) return;

    const eventListener = (event: KeyboardEvent) => {
      event.stopPropagation();
      const cleanKeyName = key.trim();
      if (!cleanKeyName.includes('+')) {
        if (
          event.key === cleanKeyName &&
          !event.altKey &&
          !event.ctrlKey &&
          !event.shiftKey &&
          !event.metaKey &&
          !shouldSuppressEvent(suppressInInputs, key)
        ) {
          event.preventDefault();
          savedHandler.current?.(event, cleanKeyName);
        }
      } else {
        const keys = cleanKeyName.split(/\s?\+\s?/); // e.g. 'crtl + a'
        if (
          keys[0] === 'ctrl' &&
          event.key === keys[1] &&
          event.ctrlKey &&
          !shouldSuppressEvent(suppressInInputs, key)
        ) {
          event.preventDefault();
          savedHandler.current?.(event, cleanKeyName);
        }
        if (
          keys[0] === 'alt' &&
          event.key === keys[1] &&
          event.altKey &&
          !shouldSuppressEvent(suppressInInputs, key)
        ) {
          event.preventDefault();
          savedHandler.current?.(event, cleanKeyName);
        }
      }
    };

    element.addEventListener('keydown', eventListener);

    return () => {
      element.removeEventListener('keydown', eventListener);
    };
  }, [key, element]);
};
